'use client';

import dynamic from 'next/dynamic';

// Dynamic Import function for phoenix react components
export const importPhoenixReactComponent = <T>(importName: string) => {
	return dynamic<T>(
		() => import('@themaven-net/phoenix-react').then((mod) => mod[importName]),
		{ ssr: false },
	);
};
