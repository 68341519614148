'use client';

import { type FC, Children, type PropsWithChildren } from 'react';
import { useScrollBarWidth } from '@/utils/hooks/use-scroll-bar-width';

export const ScrollbarWidthChecker: FC<PropsWithChildren> = ({ children }) => {
	useScrollBarWidth();

	return Children.toArray(children);
};
