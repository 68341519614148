'use client';

import type { FC, ElementType, CSSProperties, PropsWithChildren } from 'react';
import {
	useInView,
	type IntersectionOptions,
} from 'react-intersection-observer';
import {
	LAZY_WIDTH_THRESHOLD,
	LAZY_HEIGHT_THRESHOLD,
	LAZY_ASPECT_RATIO_THRESHOLD,
} from '@/constants';

import styles from './styles.module.css';

interface BaseProps extends PropsWithChildren {
	as?: ElementType;
	options?: IntersectionOptions;
	width?: number | string;
}

interface WithHeight extends BaseProps {
	aspectRatio?: never;
	height: number | string;
}

interface WithAspectRatio extends BaseProps {
	aspectRatio: number;
	height?: never;
}

export type LazyLoadWrapperProps = WithAspectRatio | WithHeight;

export const LazyLoadWrapper: FC<LazyLoadWrapperProps> = ({
	as: Component = 'div',
	aspectRatio,
	children,
	height = LAZY_HEIGHT_THRESHOLD,
	options = {},
	width = LAZY_WIDTH_THRESHOLD,
}) => {
	const { inView, ref } = useInView({
		rootMargin: '400px 0px',
		triggerOnce: true,
		...options,
	});

	const resolvedAspectRatio = aspectRatio || LAZY_ASPECT_RATIO_THRESHOLD;
	const paddingBottom = `${(1 / resolvedAspectRatio) * 100}%`;
	const childrenContent = inView ? children : null;

	const style: CSSProperties = aspectRatio
		? {
				paddingBottom: !inView ? paddingBottom : undefined,
				position: 'relative',
				width,
			}
		: {
				height,
				position: 'relative',
				width,
			};

	if (inView) {
		return childrenContent;
	}

	return (
		<Component ref={ref} style={style}>
			{aspectRatio && !inView ? (
				<div className={styles.aspectRatioNotInView}>{childrenContent}</div>
			) : (
				childrenContent
			)}
		</Component>
	);
};
