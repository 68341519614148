'use client';

import { useEffect } from 'react';
import { type Event, eventsFilterByType } from '@/utils/events';

export const useEventsByType = (
	eventType: string,
	listener: (e: Event) => unknown,
) => {
	useEffect(() => {
		const subscription = eventsFilterByType(eventType).subscribe(listener);
		return () => {
			subscription.unsubscribe();
		};
	}, [listener, eventType]);
};
