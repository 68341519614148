import { useEffect } from 'react';
import { useOnWindowResize } from '@/utils/hooks/use-on-window-resize';

const calculateScrollBarWidth = () => {
	if (!window.CSS || !window.CSS.supports('color', 'var(--some-var)')) {
		return;
	}

	let newWidth;

	if (window.innerWidth > document.body.clientWidth) {
		newWidth = window.innerWidth - document.body.clientWidth;
	} else {
		newWidth = 0;
	}

	if (newWidth > 0 && newWidth !== 15) {
		// There seems to be a bug in which after a resize the scrollbar width is
		// caught in between giving false reads. This is a workaround for that.
		requestAnimationFrame(() => calculateScrollBarWidth());
	}

	document.documentElement.style.setProperty('--scroll-bar', `${newWidth}px`);
};

export function useScrollBarWidth() {
	useEffect(() => {
		calculateScrollBarWidth();
	}, []);

	useOnWindowResize(calculateScrollBarWidth, 500);
}
