import type { Post, Taxonomy } from '@/types/entities';

export function getPostCategories(post: Partial<Post>): {
	additional: null | Taxonomy[];
	primary: null | Taxonomy;
} {
	const primary = post.meta?.primary_category_term ?? null;
	const additional: Taxonomy[] = (post.categories ?? []).filter(
		(category) => primary?.id !== category.id,
	);
	return { additional, primary };
}
