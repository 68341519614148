'use client';

import type { ReactNode } from 'react';
import { GDPR } from '@/components/GDPR';
import { loadScript } from '@/utils/load-script';
import { useSiteContext } from '@/context/SiteContext';

const SkimlinksBootstrap = (): ReactNode => {
	const { config } = useSiteContext();
	const { affiliateVendors } = config;
	const { id: skimlinksPublisherId, isEnabled: skimLinksEnabled } =
		affiliateVendors?.providers?.Skimlinks || {};
	const skimlinksScriptHost = 'https://s.skimresources.com/js/';

	if (skimLinksEnabled && skimlinksPublisherId) {
		loadScript(
			`${skimlinksScriptHost}${skimlinksPublisherId}.skimlinks.js`,
			{
				async: true,
			},
			true,
			'footer',
		);
	}
	return null;
};

export const Skimlinks = () => {
	return (
		<GDPR
			purposes={['measurement/advertising', 'measurement/content']}
			vendorId="skimlinks"
		>
			<SkimlinksBootstrap />
		</GDPR>
	);
};
