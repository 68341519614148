export const parseStack = (stack: string) => {
	const lines = stack.split('\n');
	const targetLine = lines[1] || lines[2];
	const match =
		/\((.*?):(\d+):(\d+)\)$/.exec(targetLine) ||
		/at (.*?):(\d+):(\d+)$/.exec(targetLine) ||
		/@(.*?):(\d+):(\d+)$/.exec(targetLine);
	if (!match) {
		return {
			msg: lines[0],
		};
	}
	return {
		column: match[3],
		fileName: match[1],
		line: match[2],
		msg: lines[0],
	};
};
