'use client';

import React, {
	type FC,
	useMemo,
	useState,
	useContext,
	useCallback,
	createContext,
	type RefObject,
	type PropsWithChildren,
} from 'react';

interface OverlayContextType {
	closeVariant: (variant: string, key?: string) => void;
	getOverlayRef: (
		variant: string,
		key?: string,
	) => RefObject<HTMLDialogElement> | undefined;
	isOpen: (variant: string, key?: string) => boolean;
	openVariant: (variant: string, key?: string, hash?: string) => void;
	overlaysMap: OverlayMapType;
	registerOverlay: (
		variant: string,
		key?: string,
		ref?: RefObject<HTMLDialogElement>,
		open?: boolean,
	) => void;
	unregisterOverlay: (variant: string, key?: string) => void;
}

export type OverlayMapType = Record<
	string,
	{ open: boolean; ref?: RefObject<HTMLDialogElement> }
>;

export const OverlayContext = createContext<null | OverlayContextType>(null);

function getVariantKey(variant: string, key?: string) {
	return key ? `${variant}-${key}` : variant;
}

export const OverlayProvider: FC<PropsWithChildren> = ({ children }) => {
	const [overlaysMap, setOverlaysMap] = useState<OverlayMapType>({});

	const closeVariant = useCallback(
		(v: string, key?: string) =>
			setOverlaysMap((oMap) => {
				const variantKey = getVariantKey(v, key);
				const variantRef = oMap[variantKey]?.ref;
				variantRef?.current?.close();
				return {
					...oMap,
					[variantKey]: { open: false, ref: variantRef },
				};
			}),
		[],
	);
	const getOverlayRef = useCallback(
		(
			variant: string,
			key?: string,
		): RefObject<HTMLDialogElement> | undefined => {
			const overlay = overlaysMap[getVariantKey(variant, key)];
			return overlay?.ref;
		},
		[overlaysMap],
	);
	const isOpen = useCallback(
		(variant: string, key?: string) =>
			overlaysMap[getVariantKey(variant, key)]?.open || false,
		[overlaysMap],
	);
	const openVariant = useCallback((v: string, key?: string, hash?: string) => {
		setOverlaysMap((oMap) => {
			let variant = getVariantKey(v, key);
			if (oMap[variant] === undefined) {
				variant =
					Object.keys(oMap).find((k) => k.startsWith(variant)) || variant;
			}
			oMap[variant]?.ref?.current?.showModal();
			return { ...oMap, [variant]: { ...oMap[variant], open: true } };
		});
		if (hash) {
			window.location.hash = hash;
		}
	}, []);
	const registerOverlay = useCallback(
		(
			variant: string,
			key?: string,
			ref?: RefObject<HTMLDialogElement>,
			open: boolean = false,
		) => {
			if (open) {
				ref?.current?.showModal();
			}
			setOverlaysMap((oMap) => ({
				...oMap,
				[getVariantKey(variant, key)]: { open, ref },
			}));
		},
		[],
	);
	const unregisterOverlay = useCallback((variant: string, key?: string) => {
		setOverlaysMap((oMap) => {
			const oMapCopy: OverlayMapType = {};
			const variantKey = getVariantKey(variant, key);
			Object.keys(oMap).forEach((k) => {
				if (k !== variantKey) {
					oMapCopy[k] = oMap[k];
				}
			});
			return oMapCopy;
		});
	}, []);

	const contextValue = useMemo(
		() => ({
			closeVariant,
			getOverlayRef,
			isOpen,
			openVariant,
			overlaysMap,
			registerOverlay,
			unregisterOverlay,
		}),
		[
			closeVariant,
			getOverlayRef,
			isOpen,
			openVariant,
			overlaysMap,
			registerOverlay,
			unregisterOverlay,
		],
	);

	return (
		<OverlayContext.Provider value={contextValue}>
			{children}
		</OverlayContext.Provider>
	);
};

export const useOverlayContext = () => {
	const context = useContext(OverlayContext);

	if (!context) {
		throw new Error('useOverlayContext must be used within a OverlayProvider');
	}

	return context;
};
