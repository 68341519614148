/* eslint-disable no-console */

'use client';

import Script from 'next/script';
import type { IUserContext } from '@/types/auth';
import { useSiteContext } from '@/context/SiteContext';
import { type FC, useEffect, useContext } from 'react';
import { UserContext } from '@themaven-net/phoenix-react';
import { getUserCookieConfig } from '@/utils/newsletter/auth-utils';
import type { UserContextProviderProps } from '@/types/phoenix-react-types';

import { importPhoenixReactComponent } from '../phoenix-react';
import type {
	PushlyProps,
	CommentsProfileData,
	UserSubscriptionPreferencesData,
} from './types/pushly';
import {
	pushly,
	getPlanCodes,
	isPayingSubscriber,
	isNewsletterSubscriber,
	getNewsletterSubscriptions,
} from './util/util';

const UserContextProvider =
	importPhoenixReactComponent<UserContextProviderProps>('UserContextProvider');

async function fetchCommentsProfile(
	rgis: string,
): Promise<CommentsProfileData> {
	const response = await fetch(
		`/.api/memberships/comments-profile?rgis=${encodeURIComponent(rgis)}`,
	);
	if (!response.ok) {
		const err = new Error(`${response.status}`);
		throw err;
	}

	return response.json();
}

async function fetchUserSubscriptionsPreferences(
	rgis: string,
): Promise<UserSubscriptionPreferencesData> {
	const response = await fetch(
		`/.api/memberships/user-subscription-preferences?rgis=${encodeURIComponent(rgis)}`,
	);
	if (!response.ok) {
		const err = new Error(`${response.status}`);
		throw err;
	}

	return response.json();
}

const PushlyComponent: FC<PushlyProps> = ({ config, siteKeyword }) => {
	const userContext = useContext<IUserContext>(UserContext);
	const cdnSrc = `https://cdn.p-n.io/pushly-sdk.min.js?domain_key=${config?.domainKey}`;

	useEffect(() => {
		if ('serviceWorker' in navigator) {
			navigator.serviceWorker
				.register('/sw.js')
				.then((registration) => {
					console.log(
						'Service Worker registered with scope:',
						registration.scope,
					);
				})
				.catch((error) => {
					console.error('Service Worker registration failed:', error);
				});
		}
	}, [config?.domainKey, siteKeyword]);

	useEffect(() => {
		const rgisCookie = userContext.currentUserLogin?.c3RGIS as string;
		const userId = rgisCookie?.replace(/"/g, '').split('|')[0];
		const isLoggedInUser = userId !== '' && !Number.isNaN(Number(userId));

		if (isLoggedInUser) {
			// comments profile fetch
			fetchCommentsProfile(rgisCookie).then((userInfo) => {
				pushly('external_id', userId);
				pushly('profile', {
					is_logged_in_user: isLoggedInUser,
					is_paying_subscriber: isPayingSubscriber(userInfo),
					sub_planCodes: getPlanCodes(userInfo),
				});
			});

			// newsletter profile fetch
			fetchUserSubscriptionsPreferences(rgisCookie).then((userInfo) => {
				const isNewsletterSub = isNewsletterSubscriber(userInfo);
				pushly('profile', {
					is_newsletter_subscriber: isNewsletterSub,
					newsletter_subs_list: getNewsletterSubscriptions(userInfo),
				});
			});
		}

		pushly('profile', {
			is_logged_in_user: isLoggedInUser,
		});

		pushly('load', { domainKey: config?.domainKey, sw: '/sw.js' });
	}, [userContext, config?.domainKey, siteKeyword]);

	return <Script src={cdnSrc} />;
};

export const PushlyWrapper: FC = () => {
	const { config } = useSiteContext();
	const pushlyConfig = config.analytics?.providers?.Pushly;
	const isEnabled = pushlyConfig?.isEnabled;
	return (
		isEnabled && (
			<UserContextProvider userCookieConfig={getUserCookieConfig(config)}>
				<PushlyComponent
					config={pushlyConfig}
					siteKeyword={config.siteKeyword}
				/>
			</UserContextProvider>
		)
	);
};
