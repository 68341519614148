'use client';

import Script from 'next/script';
import { GDPR } from '@/components/GDPR';
import type { Post } from '@/types/entities';
import { useSiteContext } from '@/context/SiteContext';
import { useRef, useState, useEffect, type ReactNode } from 'react';
import { eventsFilterByType, ANALYTICS_PAGE_VIEW } from '@/utils/events';

const getMetaData = (currentPost: Partial<Post>) => {
	const primaryCategory = {
		id: currentPost.primaryCategory?.id,
		name: currentPost.primaryCategory?.name,
	};

	const categories = (currentPost.categories || []).map((c) => ({
		id: c?.id,
		name: c?.name,
	}));

	const keywords = (currentPost.tags || []).map((t) => ({
		id: t.id,
		name: t.name,
	}));

	const author = {
		authorId: currentPost.authors?.[0]?.id,
		authorName: currentPost.authors?.[0]?.name,
	};

	return {
		author,
		categories,
		contentId: currentPost.meta?.tempest_id,
		keywords,
		pageType: currentPost.meta?.page_type,
		premium: 0, // FIXME: Needs api support
		primaryCategory,
		wordCount: currentPost.meta?.wordCount ?? 0,
	};
};

const trackMessage = (event: unknown) => {
	if (window.orion?.trackMessageEvent) {
		window.orion.trackMessageEvent(event);
	} else {
		window.orion.queue.push(() => {
			window.orion.trackMessageEvent(event);
		});
	}
};
const pageViewEventSentPageId = new Set();
export const SpinyAIBootstrap = (): ReactNode => {
	const [currentPost, setCurrentPost] = useState<null | Partial<Post>>(null);
	const hasInitialized = useRef(false);
	const { config } = useSiteContext();
	const spinyCMSConfig = config.analytics?.providers?.SpinyAI;

	useEffect(() => {
		const subscription = eventsFilterByType(ANALYTICS_PAGE_VIEW).subscribe(
			({ value: data }) => {
				if (!spinyCMSConfig.enabled) return;
				setCurrentPost(data.post);
			},
		);
		return () => {
			subscription.unsubscribe();
		};
	}, [spinyCMSConfig.enabled]);

	useEffect(() => {
		if (currentPost?.id && !pageViewEventSentPageId.has(currentPost.id)) {
			pageViewEventSentPageId.add(currentPost.id);
			const {
				author,
				categories,
				contentId,
				keywords,
				pageType,
				premium,
				primaryCategory,
				wordCount,
			} = getMetaData(currentPost);
			const spinyConfig = {
				context: {
					...(spinyCMSConfig.contextConfig.context || {}),
					author,
					page: {
						categories,
						contentId,
						keywords,
						pageType,
						premium,
						primaryCategory,
						wordCount,
					},
				},
			};
			let youtubeApiPromiseResolver: (value?: unknown) => void;
			const youtubeApiPromise = new Promise((resolve) => {
				youtubeApiPromiseResolver = resolve;
			});
			window.onYouTubeIframeAPIReady = () => {
				youtubeApiPromiseResolver();
			};

			window.orion = window.orion || {
				queue: [],
			};

			const queueVideo = (player: unknown) => {
				youtubeApiPromise.then(() => {
					window.orion.queue.push(() => {
						window.orion.wrapYT(player);
					});
				});
			};

			window.orion.queue.push(() => {
				window.orion.queueVideo = queueVideo;
				window.orion.setConfig(spinyConfig);
				if (pageViewEventSentPageId.size > 1) {
					window.orion.newPageView();
				}
			});

			if (!hasInitialized.current) {
				hasInitialized.current = true;
				window.addEventListener('message', trackMessage);
			}
		}

		return () => {
			window.removeEventListener('message', trackMessage);
		};
	}, [currentPost, spinyCMSConfig.contextConfig.context]);
	return (
		<>
			<Script src={spinyCMSConfig.scriptSrc} strategy="afterInteractive" />
			<GDPR purposes={['content']} vendorId="youtube">
				{/** Ref: https://developers.google.com/youtube/iframe_api_reference#Requirements */}
				<Script
					src="https://www.youtube.com/iframe_api"
					strategy="afterInteractive"
				/>
			</GDPR>
		</>
	);
};
