'use client';

import clsx from 'clsx';
import { type FC, useState } from 'react';
import { useRouter } from 'next/navigation';

import styles from './style.module.css';

export const PreviewIndicator: FC<{
	enabled: boolean;
}> = ({ enabled }) => {
	const [isVisible, setIsVisible] = useState(enabled);
	const router = useRouter();

	const handleClick = () => {
		setIsVisible(false);
		router.replace('/.api/preview/disable');
	};

	if (!enabled || !isVisible) {
		return null;
	}

	return (
		<div className={clsx(styles.previewIndicator, 'is-dark')}>
			<p>You are previewing this page.</p>

			<button
				className="button button--outline button--narrow"
				onClick={handleClick}
				type="button"
			>
				Exit Preview
			</button>
		</div>
	);
};
