import { useEffect } from 'react';

export const useLockScroll = (lock: boolean) => {
	useEffect(() => {
		if (lock) {
			document.body.classList.add('is-scroll-locked');
		} else {
			document.body.classList.remove('is-scroll-locked');
		}

		return () => {
			document.body.classList.remove('is-scroll-locked');
		};
	}, [lock]);
};
