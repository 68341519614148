'use client';

import Script from 'next/script';
import type { ReactNode } from 'react';
import { useSiteContext } from '@/context/SiteContext';
import { getParselySiteId } from '@/utils/analytics/parsely/utils';

export const ParselyBootstrap = (): ReactNode => {
	const { config } = useSiteContext();
	const siteId = getParselySiteId(config.analytics?.providers?.Parsely);
	if (siteId === undefined) {
		return null;
	}
	// based on https://docs.parse.ly/tracking-alternative-setup-options/#h-load-the-tracker-via-javascript
	const src = `https://cdn.parsely.com/keys/${siteId}/p.js`;
	return <Script id="parsely-cfg" src={src} strategy="afterInteractive" />;
};
