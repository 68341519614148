'use client';

import { useLoadScript } from '@/context/ScriptsContext';

interface SearchWidgetProps {
	configId: string;
	triggerId: string;
}

export const SearchWidget = (props: SearchWidgetProps) => {
	const { configId, triggerId } = props;

	useLoadScript(
		'google-search',
		'https://cloud.google.com/ai/gen-app-builder/client?hl=en_US',
	);

	return <gen-search-widget configId={configId} triggerId={triggerId} />;
};
