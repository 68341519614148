import { getCookie } from '@/utils/cookies';
import { safeJSONParse } from '@/utils/json-parse';

interface PermutiveIdentity {
	id: string;
	priority: number;
	tag: string;
}

/**
 * Fetches the PXID from permutive, which is cross-domain identity of the user, and returns
 * the Permutive identity object tagged as `pxid`.
 * We read the permutive config from the window object, and we use `credentials: 'include'`
 * to send the cookie with the request, so that Permutive can identify the user.
 */
export const fetchPermutiveIdentity = async (): Promise<
	PermutiveIdentity | undefined
> => {
	const { apiKey, pxidHost, workspaceId } = window.permutive?.config ?? {};

	try {
		// If the pxid.id property isn't truthy, issue the request and set it
		if (!window.pxid?.id && apiKey && workspaceId && pxidHost) {
			const url = `https://${workspaceId}.${pxidHost}/v2.0/pxid?k=${apiKey}`;
			const response = await fetch(url, {
				credentials: 'include',
				method: 'GET',
			});
			const data = await response.json();
			if (data && data.uid) {
				window.pxid = {
					id: data.uid,
					priority: 1,
					tag: 'pxid',
				};
			}
		}
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error('Error fetching Permutive identity ', error);
	}
	return window.pxid ?? undefined;
};

function getRGISUserId() {
	let cookieVal = getCookie('RGIS');
	if (cookieVal) {
		// Negative value indicates user is not authenticated (i.e. has logged out)
		if (!cookieVal.startsWith('-')) {
			cookieVal = cookieVal.replace(/"/g, '');

			// Format of rgis cookie e.g. 123456|abcde-f==
			const userid = cookieVal.split('|')[0];
			const validRegex = /(^\d+)/;
			if (validRegex.test(userid)) {
				return [userid.match(validRegex)![0], cookieVal];
			}
		}
	}
	return null;
}

/**
 *
 * Provides the logged-in state of the user. The returned object will
 * have a `type` property: `rgis`.
 * The `id` property contains the user identifier. If the user is not
 * logged in or in the event of an error, the return value will be `null`.
 * @global
 * @returns {(Object|null)} An object describing the logged-in state.
 */
function getLoggedInStatus() {
	const rgis = getRGISUserId();
	if (rgis) {
		// The actual RGIS cookie value, which we will need for making
		// entitlements middleware calls.
		return { id: rgis[0], rgis: rgis[1], type: 'rgis' };
	}
	return null;
}

/**
 * Checks if the user is logged in as a subscriber, and if so, returns the
 * Permutive identity tagged as `maven_id`.
 */
export const getLoggedInIdentity = (): PermutiveIdentity | undefined => {
	const userStatus = getLoggedInStatus();
	if (userStatus) {
		if (userStatus.type === 'rgis' && userStatus.id) {
			return {
				id: userStatus.id,
				priority: 7,
				tag: 'maven_id',
			};
		}
	}
	return undefined;
};

/**
 * Reads the `panoramaId` from local storage, and returns the Permutive identity
 * object tagged as `panorama_id`, if the value exists.
 */
export const getPanoramaIdentity = (): PermutiveIdentity | undefined => {
	const panoramaId = localStorage?.getItem?.('panoramaId');
	if (typeof panoramaId === 'string' && panoramaId) {
		return {
			id: panoramaId,
			priority: 4,
			tag: 'panorama_id',
		};
	}
	return undefined;
};

export const getPpidIdentity = (): PermutiveIdentity | undefined => {
	const permutiveId = localStorage?.getItem?.('permutive-id');
	if (typeof permutiveId === 'string' && permutiveId) {
		return {
			id: `arenaGroup-${permutiveId}`,
			priority: 5,
			tag: 'ppid',
		};
	}
	return undefined;
};

/**
 * Reads the Amazon shared ID from the the local storage, and returns the Permutive
 * identity object tagged as `shared_id`, if the value exists.
 */
export const getAmazonSharedIdentity = (): PermutiveIdentity | undefined => {
	const sharedId = localStorage?.getItem?.('_pubcid');
	if (typeof sharedId === 'string' && sharedId) {
		return {
			id: sharedId,
			priority: 8,
			tag: 'shared_id',
		};
	}
	return undefined;
};

/**
 * Reads the ID5 shared ID from the the local storage, and returns the Permutive
 * identity object tagged as `id5`, if the value exists.
 */
export const getId5Identity = (): PermutiveIdentity | undefined => {
	const id5Identity = localStorage.getItem('id5id');
	if (!id5Identity) {
		return undefined;
	}

	const value = safeJSONParse(decodeURIComponent(id5Identity));
	const universalId = value?.universal_uid;
	if (universalId && universalId !== '0') {
		return {
			id: universalId,
			priority: 6,
			tag: 'id5',
		} as PermutiveIdentity;
	}
	return undefined;
};

/**
 * Reads the `userSync.userIds` config from Prebid, and attempts to find the
 * `connect_id` and `liveramp_ats` values. If they exist, they are returned
 * as Permutive identity objects.
 */
export const getPbjsIdentities = async (): Promise<PermutiveIdentity[]> => {
	const idMap = {
		connectId: {
			priority: 9,
			tag: 'connect_id',
		},
		idl_env: {
			priority: 3,
			tag: 'liveramp_ats',
		},
	};

	return new Promise((resolve) => {
		const { pbjs } = window;
		if (typeof pbjs?.que?.push === 'function') {
			pbjs.que.push(() => {
				// Get a fresh reference to window.pbjs which may have been
				// reassigned before processing the queue.
				const newPbjs = window.pbjs;
				// This is mostly for satisfying the TS compiler
				if (!newPbjs) {
					resolve([]);
					return;
				}

				// If the async function of getUserIds lasts more than 500 ms we just return
				// the userIds that were loaded until now.
				// eslint-disable-next-line @typescript-eslint/no-floating-promises
				const timeoutPromise = new Promise<PrebidUserIds>(
					(resolveTimeoutPromise) => {
						setTimeout(() => {
							resolveTimeoutPromise(newPbjs?.getUserIds());
						}, 500);
					},
				);

				const identities: PermutiveIdentity[] = [];
				Promise.race([timeoutPromise, newPbjs.getUserIdsAsync()])
					.then((userIds) => {
						if (userIds) {
							Object.entries(userIds).forEach(([key, value_1]) => {
								const idConfig = idMap[key as keyof typeof idMap];
								if (idConfig) {
									identities.push({ ...idConfig, id: value_1 });
								}
							});
						}
						resolve(identities);
					})
					.catch(() => resolve([]));
			});
		} else {
			resolve([]);
		}
	});
};

/**
 *
 * @param {string} algorithm used to hash the string
 * @param {string} string string to hash
 * @returns {Promise<String>} string hashed converted to hexa
 */
async function hashStringWithAlg(algorithm: string, string: string) {
	const { subtle } = globalThis.crypto;
	const encodedEmail = new TextEncoder().encode(string);
	const hashedEmail = await subtle.digest(algorithm, encodedEmail);
	const hashedEmailArray = Array.from(new Uint8Array(hashedEmail));
	const hashedEmailHex = hashedEmailArray
		.map((b) => b.toString(16).padStart(2, '0'))
		.join('');
	return hashedEmailHex;
}

export const getIterableEndUserIdentity = async (): Promise<
	PermutiveIdentity | undefined
> => {
	const emailCookie = getCookie('iterableEndUserId');
	if (emailCookie) {
		const normalizedEmail = emailCookie.trim().toLowerCase();
		const emailEntityDecoded = decodeURIComponent(normalizedEmail);
		const hashedEmail = await hashStringWithAlg('SHA-256', emailEntityDecoded);
		return {
			id: hashedEmail,
			priority: 0,
			tag: 'hashed_email',
		};
	}
	return undefined;
};

/**
 * Collects all the identities from various sources and combines them into a single
 * array that can be passed to `permutive.identify` function
 */
export const collectIdentities = async (): Promise<PermutiveIdentity[]> => {
	const identities = await Promise.all([
		fetchPermutiveIdentity(),
		getPpidIdentity(),
		getId5Identity(),
		getLoggedInIdentity(),
		getPanoramaIdentity(),
		getAmazonSharedIdentity(),
		getPbjsIdentities(),
		getIterableEndUserIdentity(),
	]);

	return identities
		.flatMap((identity) => {
			if (identity !== undefined) {
				return Array.isArray(identity) ? identity : [identity];
			}
			return [];
		})
		.filter(
			(identity) =>
				!window?.identityFeatureFlags[`disable-${identity.tag}-identity`],
		);
};
