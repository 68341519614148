'use client';

import type { LazyProps } from '@/types/embeds';
import type { ConsentsProps } from '@/types/gdpr';
import { useCanRender } from '@/context/ConsentContext';
import {
	LazyLoadWrapper,
	type LazyLoadWrapperProps,
} from '@/components/LazyLoadWrapper';
import {
	type FC,
	Children,
	type ReactNode,
	isValidElement,
	type PropsWithChildren,
} from 'react';

export type GDPRCommonProps = ConsentsProps & PropsWithChildren;

export type GDPRProps = LazyProps<
	{ lazy: true } & GDPRCommonProps,
	{ lazy?: false } & GDPRCommonProps
>;

export const OnReject: FC<PropsWithChildren> = ({ children }) => children;

export const GDPR: FC<GDPRProps> = ({
	children,
	gdprApplicable,
	gppReject,
	isDemonetized,
	lazy,
	premium,
	purposes,
	vendorId,
	...rest
}) => {
	const canRender = useCanRender({
		gdprApplicable,
		gppReject,
		isDemonetized,
		premium,
		purposes,
		vendorId,
	});

	let onReject: ReactNode = null;
	const content: ReactNode[] = [];

	Children.forEach(children, (child) => {
		if (!isValidElement(child)) {
			return;
		}
		if (child.type === OnReject) {
			onReject = child;
		} else {
			content.push(child);
		}
	});

	if (!canRender) {
		return onReject;
	}

	const lazyProps = rest as LazyLoadWrapperProps;
	// eslint-disable-next-line react/jsx-no-useless-fragment -- This might contain more than one child
	const wrappedContent = <>{content}</>;

	return lazy ? (
		<LazyLoadWrapper {...lazyProps}>{wrappedContent}</LazyLoadWrapper>
	) : (
		wrappedContent
	);
};
