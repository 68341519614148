'use client';

import { usePathname } from 'next/navigation';
import { useLockScroll } from '@/utils/hooks/use-lock-scroll';
import { useMatchMedia } from '@/utils/hooks/use-match-media';
import type { MenuAttributes } from '@/components/raven/global/SubNavigation';
import {
	useRef,
	type FC,
	useMemo,
	useState,
	useEffect,
	useContext,
	useCallback,
	createContext,
	type PropsWithChildren,
} from 'react';

interface NavigationContextType {
	activeMenuItem: number;
	handleSetActiveMenuItem: (value: number) => void;
	handleSetIsMenuOpen: (value: boolean) => void;
	handleSetIsNewsletterOpen: (value: boolean) => void;
	handleSetIsSubNavRender: (value: boolean) => void;
	handleSetSubNavigationOpen: (value: boolean) => void;
	isFirstSubNavRender: boolean;
	isMenuOpen: boolean;
	isNewsletterOpen: boolean;
	isSmallMenu: boolean;
	isSubNavigationOpen: boolean;
	lastVisitedPath: string;
	subNavigation: MenuAttributes[];
}

export const NavigationContext = createContext<NavigationContextType | null>(
	null,
);

interface NavigationProviderProps extends PropsWithChildren {
	subMenu?: MenuAttributes[];
}

export const NavigationProvider: FC<NavigationProviderProps> = ({
	children,
	subMenu,
}) => {
	const path = usePathname();
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [activeMenuItem, setActiveMenuItem] = useState(-1);
	const [isNewsletterOpen, setIsNewsletterOpen] = useState(false);
	const [isSubNavigationOpen, setIsSubNavigationOpen] = useState(false);
	const [subNavigation] = useState<MenuAttributes[]>(subMenu || []);
	const lastVisitedPath = useRef<string>('');
	// This is used to determine if the sub navigation should be rendered on the first render. Annoying the user with
	// the sub navigation animating in on the first render is not the best user experience. Because how the app is,
	// the header is recreated during page navigation but the Context is safe from being recreated. This is why we
	// need to keep track of the first render of the sub navigation.
	const [isFirstSubNavRender, setIsFirstSubNavRender] = useState(true);

	const [isSmallMenu] = useMatchMedia(['(max-width: 1023px)']);

	const handleSetIsMenuOpen = useCallback((value: boolean) => {
		setIsMenuOpen(value);
	}, []);

	const handleSetActiveMenuItem = useCallback((value: number) => {
		setActiveMenuItem(value);
	}, []);

	const handleSetIsNewsletterOpen = useCallback((value: boolean) => {
		setIsNewsletterOpen(value);
	}, []);

	const handleSetSubNavigationOpen = useCallback((value: boolean) => {
		setIsSubNavigationOpen(value);
	}, []);

	const handleSetIsSubNavRender = useCallback((value: boolean) => {
		setIsFirstSubNavRender(value);
	}, []);

	// Store the last visited path.
	// This is used to determine the sub navigation when the user navigates back to a previous page.
	useEffect(() => {
		if (lastVisitedPath.current !== path) {
			lastVisitedPath.current = path;
		}
	}, [path]);

	// Close the menu when the path changes.
	// This is triggers when the user navigates to a new page.
	useEffect(() => {
		setIsMenuOpen(false);
		setIsNewsletterOpen(false);
		setActiveMenuItem(-1);
	}, [path, handleSetIsMenuOpen]);

	useLockScroll(isSmallMenu && isMenuOpen);

	const contextValue = useMemo(
		() => ({
			activeMenuItem,
			handleSetActiveMenuItem,
			handleSetIsMenuOpen,
			handleSetIsNewsletterOpen,
			handleSetIsSubNavRender,
			handleSetSubNavigationOpen,
			isFirstSubNavRender,
			isMenuOpen,
			isNewsletterOpen,
			isSmallMenu,
			isSubNavigationOpen,
			lastVisitedPath: lastVisitedPath.current,
			subNavigation,
		}),
		[
			activeMenuItem,
			handleSetActiveMenuItem,
			handleSetIsMenuOpen,
			handleSetIsNewsletterOpen,
			handleSetSubNavigationOpen,
			handleSetIsSubNavRender,
			isFirstSubNavRender,
			isMenuOpen,
			isNewsletterOpen,
			isSmallMenu,
			isSubNavigationOpen,
			subNavigation,
		],
	);

	return (
		<NavigationContext.Provider value={contextValue}>
			{children}
		</NavigationContext.Provider>
	);
};

export const useNavigationContext = () => {
	const context = useContext(NavigationContext);

	if (!context) {
		throw new Error(
			'useNavigationContext must be used within a NavigationProvider',
		);
	}

	return context;
};
