export const LAUNCHER_SCRIPT_SELECTOR = 'data-spotim-module="spotim-launcher"';
export const OW_SDK_EVENT = 'ow-sdk-event';
export const SPOTIM_EVENT: { [x: string]: OpenWebEventTypes } = {
	apiReady: 'spot-im-api-ready',
	authSuccess: 'spot-im-user-auth-success',
	loginStart: 'spot-im-login-start',
	renewSSO: 'spot-im-renew-sso',
	ssoError: 'spot-im-sso-error',
	userLogout: 'spot-im-user-logout',
};
export interface EventPayload {
	spotImClass: string;
}
export interface UserData {
	displayName: string;
	email: string;
	id: string;
	imageId: string;
	isAdmin: boolean;
	isEmailVerified: boolean;
	isRegistered: boolean;
	username: string;
}
export type OpenWebEvent = CustomEvent<{
	payload: EventPayload | UserData;
	type: OpenWebEventTypes;
}>;
export type OWEHandlerType = (e: OpenWebEvent) => void;
const SSO_LOGIN_HANDSHAKE_ENDPOINT =
	'/.api/social/open-web-sso-login-handshake';

export type OpenWebEventTypes =
	| 'spot-im-api-ready'
	| 'spot-im-login-start'
	| 'spot-im-renew-sso'
	| 'spot-im-sso-error'
	| 'spot-im-user-auth-success'
	| 'spot-im-user-click'
	| 'spot-im-user-logout'
	| 'spot-im-user-modal-closed'
	| 'spot-im-user-modal-opened';

const RAVEN_URL = process.env.RAVEN_URL || '';

export const ssoCallback =
	(rgis: string) =>
	async (
		codeA: string,
		completeSSOCallback: (codeB: null | string, err?: unknown) => void,
	) => {
		// call backend to receive codeB and return it
		// to OpenWeb via completeSSOCallback function
		let response;
		try {
			response = await fetch(`${RAVEN_URL}${SSO_LOGIN_HANDSHAKE_ENDPOINT}`, {
				body: JSON.stringify({
					code_a: codeA,
				}),
				headers: {
					'Content-Type': 'application/json',
					'x-rgis': rgis.replace(/^"/, '').replace(/"$/, ''),
				},
				method: 'POST',
			});
			if (response.ok) {
				const codeB = await response.json();
				completeSSOCallback(codeB);
			}
		} catch (error) {
			completeSSOCallback(null, error);
		}
	};
export async function openWebStartSSO(
	userId: string,
	callback: (
		codeA: string,
		completeSSOCallback: (codeB: null | string, err?: unknown) => void,
	) => void,
): Promise<UserData | void> {
	if (!window.SPOTIM?.startTTH) {
		const startHandshakeOnApiReady = async () => {
			openWebStartSSO(userId, callback);
			document.removeEventListener(
				SPOTIM_EVENT.apiReady,
				startHandshakeOnApiReady,
			);
		};

		document.addEventListener(
			SPOTIM_EVENT.apiReady,
			startHandshakeOnApiReady,
			false,
		);
	}
	return window.SPOTIM.startTTH({ callback, userId });
}

export function logout() {
	if (window.SPOTIM?.logout) {
		window.SPOTIM.logout();
	}
}
