import {
	map,
	filter,
	events$,
	bufferTime,
	type Observable,
	eventsFilterByType,
	ANALYTICS_LI_PUBADS_EVENT,
} from '@/utils/events';

function maybeRemove<X>(x?: X): undefined | X {
	return x === null ? undefined : x;
}

export type EventName =
	| 'impressionViewable'
	| 'slotOnload'
	| 'slotRenderEnded'
	| 'slotRequested'
	| 'slotResponseReceived';

export interface SlotEventFields {
	// tempest's slot-level targeting adindex
	adindex?: number;
	// tempest's slot-level targeting adzone
	adzone?: string;
	// cpmm from hb_pb targeting
	cpmm?: number;
	ids: string[];
	// the sizes that we specified in adServerDfp.js;
	// it can be {width, height}, or it can be a string like 'fluid'
	sizes: ({ height: number; width: number } | string)[] | undefined;
	slotElementId: string;
	// browser date in millis
	ts: number;
	tspl: number;
}

export interface SlotRenderEndedFields extends SlotEventFields {
	advertiserId?: number;
	campaignId?: number;
	creativeId?: number;
	isEmpty: boolean;
	lineItemId?: number;
	size?: number[] | string;
	sourceAgnosticCreativeId?: number;
	sourceAgnosticLineItemId?: number;
}

const getGenericEventFields = (
	slot: googletag.Slot,
	includeSizes: boolean,
): SlotEventFields => {
	const pbArray = slot.getTargeting('hb_pb');
	const adzone = slot.getTargeting('adzone')[0];
	const adindex = slot.getTargeting('adindex')[0];

	const bidString: string | undefined = pbArray[0];
	let cpmm: number | undefined = Math.round(+bidString * 1000);
	cpmm = Number.isFinite(cpmm) ? cpmm : undefined;

	const slotSizes = slot
		// @ts-expect-error getSizes does exist
		.getSizes()
		.map((size: { height: string; width: string } | string) =>
			typeof size === 'string'
				? size
				: { height: size.height, width: size.width },
		);

	return {
		adindex: typeof adindex !== 'undefined' ? Number(adindex) : undefined,
		adzone,
		cpmm,
		ids: [], // TODO: trackIdentities
		sizes: includeSizes ? slotSizes : undefined,
		slotElementId: slot.getSlotElementId(),
		ts: Date.now(),
		// eslint-disable-next-line no-bitwise
		tspl: window.performance.now() | 0,
	};
};

const track = (
	eventName: EventName,
	event: SlotEventFields | SlotRenderEndedFields,
) =>
	events$.next({
		type: ANALYTICS_LI_PUBADS_EVENT,
		value: { name: eventName, ...event },
	});

type PubAdEvent = {
	type: typeof ANALYTICS_LI_PUBADS_EVENT;
	value: { name: EventName } & (SlotEventFields | SlotRenderEndedFields);
};
type PubAdActivity = Partial<
	Record<EventName, (SlotEventFields | SlotRenderEndedFields)[]>
>;

export const liPubadsEvents$ = (
	eventsFilterByType(ANALYTICS_LI_PUBADS_EVENT) as Observable<PubAdEvent>
).pipe(
	bufferTime(1000),
	filter((b: PubAdEvent[]) => b.length > 0),
	map((b: PubAdEvent[]) => (b.length > 32 ? b.slice(0, 32) : b)),
	map((b: PubAdEvent[]) =>
		b.reduce((p, { value: { name, ...event } }) => {
			// eslint-disable-next-line no-param-reassign
			p[name] = [...(p[name] || []), event];
			return p;
		}, {} as PubAdActivity),
	),
	map((b: PubAdActivity) =>
		Object.entries(b).map(([k, v]) => [k, v.map((e) => JSON.stringify(e))]),
	),
	map(Object.fromEntries),
);

export const sendBatch = (batch: { [k in EventName]: string }) => {
	window.$p('send', 'pubads', batch);
};

export const onSlotRenderEnded = (
	event: googletag.events.SlotRenderEndedEvent,
) => {
	// see https://developers.google.com/doubleclick-gpt/reference#googletag.events.SlotRenderEndedEvent
	const evt = {
		...getGenericEventFields(event.slot, false),
		advertiserId: maybeRemove(event.advertiserId),
		campaignId: maybeRemove(event.campaignId),
		creativeId: maybeRemove(event.creativeId),
		isEmpty: event.isEmpty,
		lineItemId: maybeRemove(event.lineItemId),
		size: maybeRemove(event.size),
		sourceAgnosticCreativeId: maybeRemove(event.sourceAgnosticCreativeId),
		sourceAgnosticLineItemId: maybeRemove(event.sourceAgnosticLineItemId),
	};

	track('slotRenderEnded', evt);
};

export const onSlotRequested = (event: googletag.events.SlotRequestedEvent) => {
	track('slotRequested', getGenericEventFields(event.slot, true));
};

export const onSlotResponseReceived = (
	event: googletag.events.SlotRequestedEvent,
) => {
	track('slotResponseReceived', getGenericEventFields(event.slot, false));
};

export const onSlotOnload = (
	event: googletag.events.ImpressionViewableEvent,
) => {
	track('slotOnload', getGenericEventFields(event.slot, false));
};

export const onImpressionViewable = (
	event: googletag.events.ImpressionViewableEvent,
) => {
	track('impressionViewable', getGenericEventFields(event.slot, false));
};
