import { useState } from 'react';
import { isClient } from '@/utils/is-client';
import { useSafeLayoutEffect } from '@/utils/hooks/use-safe-layout-effect';

type IMediaQuery = string[];
type IMatchedMedia = boolean[];

export function useMatchMedia(
	queries: IMediaQuery,
	defaultValues: IMatchedMedia = [],
): IMatchedMedia {
	const initialValues = defaultValues.length
		? defaultValues
		: Array(queries.length).fill(false);
	const mediaQueryLists = queries.map((query) =>
		isClient ? window.matchMedia(query) : null,
	);
	const getValue = (): IMatchedMedia =>
		mediaQueryLists.map((mql) => mql?.matches || false);

	const [value, setValue] = useState(getValue);

	useSafeLayoutEffect(() => {
		const handler = (): void => setValue(getValue);
		mediaQueryLists.forEach((mql) => mql?.addEventListener('change', handler));
		// Remove listeners on cleanup
		return (): void =>
			mediaQueryLists.forEach((mql) =>
				mql?.removeEventListener('change', handler),
			);
	}, []);

	if (!isClient) {
		return initialValues;
	}

	return value;
}
