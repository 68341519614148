'use client';

import Script from 'next/script';
import { GDPR } from '@/components/GDPR';

export const BrandMetrics = () => {
	return (
		<GDPR
			purposes={[
				'storage',
				'personalization',
				'advertising',
				'measurement/development',
			]}
			vendorId="brandmetrics"
		>
			<Script
				async
				src="https://cdn.brandmetrics.com/tag/13cbb388-4e97-49d1-b869-8f727ffcf088/arena_group.js"
			/>
		</GDPR>
	);
};
