'use client';

import {
	type FC,
	useMemo,
	useState,
	useContext,
	useCallback,
	createContext,
	type PropsWithChildren,
} from 'react';

interface VideoPlayerContextType {
	firstVideoPlayed: string;
	handleOnFirstVideoPlay: (value: string) => void;
}

const VideoPlayerContext = createContext<undefined | VideoPlayerContextType>(
	undefined,
);

export const VideoPlayerProvider: FC<PropsWithChildren> = ({ children }) => {
	const [firstVideoPlayed, setFirstVideoPlayed] = useState('');

	const handleOnFirstVideoPlay = useCallback((videoId: string) => {
		setFirstVideoPlayed(videoId);
	}, []);

	const contextValue = useMemo(
		() => ({
			firstVideoPlayed,
			handleOnFirstVideoPlay,
		}),
		[firstVideoPlayed, handleOnFirstVideoPlay],
	);

	return (
		<VideoPlayerContext.Provider value={contextValue}>
			{children}
		</VideoPlayerContext.Provider>
	);
};

export const useVideoPlayerContext = () => {
	const context = useContext(VideoPlayerContext);

	if (!context) {
		throw new Error(
			'useVideoPlayerContext must be used within a VideoPlayerProvider',
		);
	}

	return context;
};
