'use client';

import { useRef, useEffect } from 'react';
import { usePathname } from 'next/navigation';
import { useAdService } from '@/context/AdServiceContext';
import { events$, ARENA_PAGE_VIEW } from '@/utils/events';

export const NavigationEvents = () => {
	const pathname = usePathname();
	const adService = useAdService();
	const currentPathname = useRef(pathname);

	useEffect(() => {
		if (pathname !== currentPathname.current) {
			// Generates new adService.viewCorrelator for every page.
			// adService.continuousScrollCorrelator tracks the landing correlator.
			adService.rotateCorrelator();
			currentPathname.current = pathname;
			events$.next({
				type: ARENA_PAGE_VIEW,
				value: { correlator: adService.viewCorrelator, path: currentPathname },
			});
		}
	}, [adService, pathname]);

	return null;
};
