'use client';

import clsx from 'clsx';
import Close from '@themaven-net/raven-assets/Close';
import Tally1 from '@themaven-net/raven-assets/Tally1';
import { useSiteContext } from '@/context/SiteContext';
import { type FC, useEffect, useCallback } from 'react';

import styles from './styles.module.css';
import type { ToastProps } from './types';

export const Toaster: FC = () => {
	const { toaster } = useSiteContext();
	const { setToast, toast } = toaster;

	const removeToast = useCallback(() => {
		setToast({} as ToastProps);
	}, [setToast]);

	// Automatically remove toast after 10 seconds
	useEffect(() => {
		if (toast.message) {
			const timer = setTimeout(removeToast, 10000);
			return (): void => clearTimeout(timer);
		}
		return undefined;
	}, [toast, removeToast]);

	if (!toast.message) return null;

	return (
		<div
			className={clsx('is-elevation-2', styles.toaster, {
				[styles.error]: toast.type === 'error',
				[styles.info]: toast.type === 'info',
				[styles.success]: toast.type === 'success',
			})}
		>
			<span className={styles.message}>{toast.message}</span>
			<Tally1 className={styles.separator} />
			<button onClick={removeToast} type="button">
				<Close />
			</button>
		</div>
	);
};
