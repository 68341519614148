import type {
	PushlyArgs,
	CommentsProfileData,
	UserSubscriptionPreferencesData,
} from '../types/pushly';

// Function to check if the user is a paying subscriber
// We pass in the user info object and check if the subscriptions
// array is populated, but this integration currently
// only supports theStreet / theStreetPro
// subscriptions
export function isPayingSubscriber(userInfo?: CommentsProfileData): boolean {
	return (userInfo?.subscriptions?.length ?? 0) > 0;
}

// Function to get planCodes from paid subscriptions array
export function getPlanCodes(userInfo?: CommentsProfileData) {
	return userInfo?.subscriptions?.map((sub) => sub.product);
}

// Function to check if a user is a newsletter subscriber
export function isNewsletterSubscriber(
	userInfo: UserSubscriptionPreferencesData,
): boolean {
	return (userInfo?.newsletters?.length ?? 0) > 0;
}

// Function to get titles of newsletter subscriptions
export function getNewsletterSubscriptions(
	userInfo: UserSubscriptionPreferencesData,
) {
	return userInfo?.newsletters?.map((newsletter) =>
		newsletter?.title?.toLowerCase(),
	);
}

export function pushly(...args: PushlyArgs) {
	window.PushlySDK = window.PushlySDK || [];
	window.PushlySDK.push(args);
}
