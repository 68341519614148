'use client';

import { useRef, useEffect } from 'react';
import { isClient } from '@/utils/is-client';
import type { Experiment } from '@/types/experiment';
import { parseStack } from '@/utils/parse-error-stack';
import { useSiteContext } from '@/context/SiteContext';
import { useAdService } from '@/context/AdServiceContext';
import { useBreakpoint } from '@/utils/hooks/use-breakpoint';
import { useConsentContext } from '@/context/ConsentContext';
import {
	events$,
	type Event,
	bufferUntil,
	windowError$,
	ARENA_PAGE_VIEW,
	type Observable,
	ANALYTICS_LI_LOAD,
	USER_ARTICLE_VIEW,
	eventsFilterByType,
	ARTICLE_TITLE_ENTER,
	ARTICLE_CONTENT_ENTER,
	ARTICLE_FETCH_COMPLETE,
	type ArenaPageViewEvent,
	ANALYTICS_LI_REPORT_ERROR,
	windowUnhandledRejections$,
} from '@/utils/events';

import {
	sendBatch,
	onSlotOnload,
	liPubadsEvents$,
	onSlotRequested,
	onSlotRenderEnded,
	onImpressionViewable,
	onSlotResponseReceived,
} from './dfpReporter';

export const jsKey = '999gba4qphtjrmpe';

export interface LiReportErrorEvent extends Event {
	value: {
		error: Error;
		note: string;
	};
}

if (isClient) {
	const liLoad$ = eventsFilterByType(ANALYTICS_LI_LOAD);
	bufferUntil({ done$: liLoad$, src$: windowError$ }).subscribe((e) => {
		const error = e as unknown as Error;
		const { column, fileName, line, msg } = parseStack(error.stack || '');
		window.$p(
			'customErrorHandler',
			msg || error.message,
			fileName,
			line,
			column,
			error,
		);
	});
	bufferUntil({ done$: liLoad$, src$: windowUnhandledRejections$ }).subscribe(
		(e) => {
			const error = e as unknown as PromiseRejectionEvent;
			const { column, fileName, line, msg } = parseStack(
				error.reason.stack || '',
			);
			window.$p(
				'customErrorHandler',
				`unhandledrejection: ${msg ?? error.reason.message}`,
				fileName,
				line,
				column,
				error.reason,
			);
		},
	);
	const liErrors$ = eventsFilterByType(ANALYTICS_LI_REPORT_ERROR);
	bufferUntil({ done$: liLoad$, src$: liErrors$ }).subscribe((liErrorEvent) => {
		if (liErrorEvent) {
			const {
				value: { error, note },
			} = liErrorEvent as unknown as LiReportErrorEvent;
			if (error && note) {
				const { column, fileName, line, msg } = parseStack(error.stack || '');
				window.$p(
					'customErrorHandler',
					`handlederror: ${note} - ${msg}`,
					fileName,
					line,
					column,
					error,
				);
			}
		}
	});
	window.reportErrorToPetametrics = (note: string, error: Error) =>
		events$.next({ type: ANALYTICS_LI_REPORT_ERROR, value: { error, note } });
}

const continuousScrollWidgetName = 'raven-continuous-scroll';

export type ContinuousScrollFetchPromise = { ids?: number[] } & Promise<
	number[]
>;

export const fetchContinuousScroll = (
	siteKeyword: string,
): ContinuousScrollFetchPromise => {
	const promise: ContinuousScrollFetchPromise = new Promise((resolve) => {
		window.$p('register', {
			callback: ({
				items,
			}: {
				items: { externalReferences_wordpress: string }[];
			}) => {
				const ids: number[] = [];
				items.forEach((item) => {
					const [, id] =
						item.externalReferences_wordpress.match(/^wordpress-post-(\d+)$/) ??
						[];
					if (id) ids.push(parseInt(id, 10));
				});
				promise.ids = ids;
				resolve(ids);
			},
			max: 10,
			opts: {
				mf: ['externalReferences_wordpress'],
				rf: ['externalReferences_wordpress'],
				site_keyword: siteKeyword,
			},
			widget: continuousScrollWidgetName,
		});
		window.$p('fetch');
	});
	return promise;
};

export const LiftIgniterInit = ({
	experiments,
	generationTimestamp,
	ravenVersion,
}: {
	experiments: Experiment[];
	generationTimestamp: number;
	ravenVersion: string;
}) => {
	const { arenaContext } = useConsentContext();
	const adService = useAdService();
	const breakpoint = useBreakpoint();
	const { config } = useSiteContext();

	useEffect(() => {
		adService.addEventListener('slotRequested', onSlotRequested);
		adService.addEventListener('slotResponseReceived', onSlotResponseReceived);
		adService.addEventListener('slotRenderEnded', (e) =>
			onSlotRenderEnded(e as googletag.events.SlotRenderEndedEvent),
		);
		adService.addEventListener('slotOnload', onSlotOnload);
		adService.addEventListener('impressionViewable', onImpressionViewable);
	}, [adService]);

	useEffect(() => {
		const subscription = liPubadsEvents$.subscribe(sendBatch);
		return () => {
			subscription.unsubscribe();
		};
	}, []);

	useEffect(() => {
		const subscription = (
			eventsFilterByType(ARENA_PAGE_VIEW) as Observable<ArenaPageViewEvent>
		).subscribe(({ value: { correlator } }) => {
			window.$p('updateConfig', {
				globalCtx: { mavenCorrelator: correlator },
			});
			window.$p('resetPageview');
		});
		return () => {
			subscription.unsubscribe();
		};
	});

	const csEnabled = Boolean(config.enabledFeatures['li-continuous-scroll']);
	useEffect(() => {
		const source = csEnabled ? 'LI' : 'base';
		const shown = new Map<
			string,
			{ ccu: string; click?: boolean; visible?: boolean }
		>();
		const send = (e: string, ccu: string, vi: string, clickUrl?: string) => {
			window.$p('send', `widget_${e}`, {
				ccu,
				clickUrl,
				cu: ccu,
				source,
				vi,
				w: continuousScrollWidgetName,
			});
		};
		const sendOnce = (
			e: 'click' | 'visible',
			vi: string,
			clickUrl?: string,
		) => {
			const s = shown.get(vi);
			if (s && !s[e]) {
				s[e] = true;
				send(e, s.ccu, vi, clickUrl);
			}
		};
		const subscription = events$.subscribe((e) => {
			switch (e.type) {
				case USER_ARTICLE_VIEW:
					if (e.value.isInitial) {
						shown.clear();
					}
					break;
				case ARTICLE_FETCH_COMPLETE: {
					const ccu = e.value.lastArticle.link;
					const vi = e.value.article.link;
					shown.set(vi, { ccu });
					send('shown', ccu, vi);
					break;
				}
				case ARTICLE_TITLE_ENTER: {
					const vi = e.value.link;
					sendOnce('visible', vi);
					break;
				}
				case ARTICLE_CONTENT_ENTER: {
					const vi = e.value.link;
					sendOnce('visible', vi);
					sendOnce('click', vi, vi);
					break;
				}
				default:
					break;
			}
		});
		return () => {
			subscription.unsubscribe();
		};
	}, [csEnabled]);

	const isInitialized = useRef(false);

	useEffect(() => {
		if (typeof window.$p !== 'undefined' && !isInitialized.current) {
			window.$p('init', jsKey, {
				config: {
					globalCtx: {
						breakpoint,
						genTs: generationTimestamp,
						isDemonetized: arenaContext?.isDemonetized,
						mavenCorrelator: adService.viewCorrelator,
						ravenVersion,
						site_keyword: config.siteKeyword,
						site_name: config.siteName,
						tempestExperiments: experiments.map(({ variant }) => variant),
					},
				},
			});
			if (arenaContext?.uid) {
				window.$p('setUserId', arenaContext?.uid, 'replaceIfEmpty');
			}
			window.$p('send', 'pageview');
			window.$p('onload', () => events$.next({ type: ANALYTICS_LI_LOAD }));
			isInitialized.current = true;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return null;
};
