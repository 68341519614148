type ParselyConfig = {
	isEnabled: boolean;
	siteId: string;
};

export const getParselySiteId = (
	parselyConfig: ParselyConfig,
): string | undefined => {
	const isEnabled = parselyConfig?.isEnabled;
	if (!isEnabled) {
		return undefined;
	}
	const { siteId } = parselyConfig;
	if (siteId?.length === 0) {
		return undefined;
	}
	return siteId;
};
