'use client';

import type { AppSettings } from '@/utils/data/fetch-app-settings';
import {
	type FC,
	useMemo,
	useContext,
	createContext,
	type PropsWithChildren,
} from 'react';

type AppSettingsContextType = AppSettings;

const AppSettingsContext = createContext<AppSettingsContextType | undefined>(
	undefined,
);
export const useAppSettings = () => {
	const context = useContext(AppSettingsContext);

	if (!context) {
		throw new Error('useAppSettings must be used within a AppSettingsProvider');
	}

	return context;
};

export const AppSettingsProvider: FC<
	PropsWithChildren<{
		settings: AppSettings;
	}>
> = ({ children, settings }) => {
	const contextValue: AppSettingsContextType = useMemo(
		() => settings,
		[settings],
	);

	return (
		<AppSettingsContext.Provider value={contextValue}>
			{children}
		</AppSettingsContext.Provider>
	);
};
