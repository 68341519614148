'use client';

import Script from 'next/script';
import { type FC, Fragment } from 'react';
import { removeEmpty } from '@/utils/remove-empty';
import type { ComscoreProps } from '@/types/comscore';
import { useEventsByType } from '@/utils/hooks/use-events';
import { ARENA_PAGE_VIEW, type ArenaPageViewEvent } from '@/utils/events';
import { useCanRender, useConsentContext } from '@/context/ConsentContext';

export const Comscore: FC<ComscoreProps> = ({ config }) => {
	const { isGDPRApplicable } = useConsentContext();
	const canRender = useCanRender({
		purposes: ['measurement'],
		vendorId: 'comscore',
	});
	let consentType = '';
	if (!isGDPRApplicable) {
		consentType = 'not-applicable';
	} else if (canRender) {
		consentType = 'consented';
	}
	useEventsByType(ARENA_PAGE_VIEW, (e) => {
		if (!canRender) return;

		const { correlator } = (e as ArenaPageViewEvent).value;
		config.forEach(({ comscore_id }) => {
			window.COMSCORE?.beacon({ c1: '2', c2: comscore_id });
			// c=correlator to ensure this is never cached as per Comscore's instruction.
			fetch(`/third-party/comscore/page-view-candidate?c=${correlator}`, {
				cache: 'no-store',
			});
		});
	});

	return (
		canRender && (
			<>
				{config.map(
					({
						brand = '',
						campaign_id = '',
						comscore_id,
						site_url,
						tag_type,
					}) => {
						const comscoreTag = removeEmpty({
							c1: tag_type,
							c2: comscore_id,
							c3: campaign_id,
							c4: site_url ? encodeURIComponent(site_url) : '',
							c6: brand,
							cs_ucfr: consentType === 'consented' ? '1' : '',
							options: {
								bypassUserConsentRequirementFor1PCookie:
									consentType === 'not-applicable' ? true : '',
								enableFirstPartyCookie: true,
							},
						});

						return (
							comscore_id && (
								<Fragment key={`comscore-${comscore_id}`}>
									<Script
										src={`https://sb.scorecardresearch.com/cs/${comscore_id}/beacon.js`}
									/>
									<Script
										dangerouslySetInnerHTML={{
											__html: `
												window._comscore = window._comscore || [];
												window._comscore.push(${JSON.stringify(comscoreTag)});
											`,
										}}
										id={`comscore-${comscore_id}`}
									/>
								</Fragment>
							)
						);
					},
				)}
			</>
		)
	);
};
