'use client';

import type { Post } from '@/types/entities';
import { useSiteContext } from '@/context/SiteContext';
import { useOverlayContext } from '@/context/OverlayContext';
import { useAppSettings } from '@/context/AppSettingsContext';
import type { LoginFormStatus } from '@/components/raven/global/LoginForm';
import {
	OW_SDK_EVENT,
	type OpenWebEvent,
} from '@/components/raven/Comments/OpenWeb';
import {
	useRef,
	type FC,
	useMemo,
	useState,
	useEffect,
	useContext,
	useCallback,
	createContext,
	type MutableRefObject,
	type PropsWithChildren,
} from 'react';

interface CommentsContextType {
	commentsEnabled: (post: Post) => boolean;
	errorMessage: null | string;
	isConversationEnabled: boolean;
	openCommentsDrawer: (post: Post) => void;
	openWebEventsRef: MutableRefObject<(event: OpenWebEvent) => void>;
	setErrorMessage: React.Dispatch<React.SetStateAction<null | string>>;
	setShowLogin: React.Dispatch<React.SetStateAction<boolean>>;
	setStatus: React.Dispatch<React.SetStateAction<LoginFormStatus>>;
	showLogin: boolean;
	spotId?: string;
	status: LoginFormStatus;
}

export const COMMENTS_VARIANT = 'CommentsDrawer';
export const CommentsContext = createContext<CommentsContextType | null>(null);

export const CommentsProvider: FC<PropsWithChildren> = ({ children }) => {
	const [errorMessage, setErrorMessage] = useState<null | string>(null);
	const [showLogin, setShowLogin] = useState(false);
	const [status, setStatus] = useState<LoginFormStatus>('idle');
	const openWebEventsRef = useRef<(event: OpenWebEvent) => void>(
		(e: OpenWebEvent) => {},
	);

	const { openVariant } = useOverlayContext();
	const {
		settings: { comments },
	} = useAppSettings();
	const { config } = useSiteContext();
	const { openWeb: { isConversationEnabled, spotId } = {} } = config;

	const checkIfShouldShowComments = useCallback(() => {
		const { hash } = window.location;
		const shouldShowComments = /^#comments/.test(hash);
		if (shouldShowComments) {
			openVariant(COMMENTS_VARIANT);
		}
		if (new URLSearchParams(window.location.search).get('apiKey')) {
			setShowLogin(true);
		}
	}, [openVariant]);

	const commentsEnabled = useCallback(
		(post: Post) =>
			// Comments enabled site wide
			(isConversationEnabled &&
				// Comments enabled per Post Type
				comments?.by_post_type[post.type] &&
				// Comments enabled at post level
				post.meta.has_comments_enabled &&
				Boolean(post.meta.tempest_id)) ??
			false,
		[comments, isConversationEnabled],
	);

	const handleOWEvent = useCallback(
		(e: OpenWebEvent) => openWebEventsRef.current(e),
		[],
	);

	const openCommentsDrawer = useCallback(
		(post: Post) => {
			openVariant(COMMENTS_VARIANT, post.meta.tempest_id);
		},
		[openVariant],
	);

	useEffect(() => {
		checkIfShouldShowComments();
	}, [checkIfShouldShowComments]);

	useEffect(() => {
		document.addEventListener(OW_SDK_EVENT, handleOWEvent);
		return () => {
			document.removeEventListener(OW_SDK_EVENT, handleOWEvent);
		};
	}, [handleOWEvent]);

	const contextValue = useMemo(
		() => ({
			commentsEnabled,
			errorMessage,
			isConversationEnabled: true,
			openCommentsDrawer,
			openWebEventsRef,
			setErrorMessage,
			setShowLogin,
			setStatus,
			showLogin,
			spotId,
			status,
		}),
		[
			commentsEnabled,
			errorMessage,
			openCommentsDrawer,
			openWebEventsRef,
			setErrorMessage,
			setShowLogin,
			setStatus,
			showLogin,
			spotId,
			status,
		],
	);

	return (
		<CommentsContext.Provider value={contextValue}>
			{children}
		</CommentsContext.Provider>
	);
};

export const useCommentsContext = () => {
	const context = useContext(CommentsContext);

	if (!context) {
		throw new Error(
			'useCommentsContext must be used within a CommentsProvider',
		);
	}

	return context;
};
